<template>
    <div id="topup-wallet" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2" aria-hidden="true">
        <div class="modal-dialog">
            <div v-if="transactionMode=='start'" class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel2">TopUp Wallet</h4>
                    <button ref="topup_close_button" type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                </div>
                <div class="modal-body">
                    <form class="form-horizontal form-element">
                        <div class="col-md-12 mb-20">
                            <p class="text-danger" v-if="error">{{errorMessage}}</p>
                        </div>
                        <div class="col-md-12 mb-20">
                            <div class="form-group">
                                <input v-model="amount" type="number" class="form-control" placeholder="Enter Amount To Top Up">
                                <p class="error" v-if="amountError">{{amountErrorMessage}}</p>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-info" @click="topupWallet">Top up<span v-if="loading" class="spinner-border text-white"></span></button>
                    <button type="button" class="btn btn-default float-right" data-dismiss="modal">Cancel</button>
                </div>
            </div>
            <div v-if="transactionMode=='confirm'" class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel2">Confirm Topup Transaction</h4>
                    <button ref="topup_close_button" type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                </div>
                <div class="modal-body">
                    <div class="col-md-12 mb-20">
                        <p class="text-danger" v-if="error">{{errorMessage}}</p>
                    </div>
                    <div>					
                      <div class="table-responsive">
                          <table class="table mt-0 table-hover no-wrap">
                            <tr>
                              <td>Amount</td>
                              <td>{{ confirmTransactionDetails.total_amount }}</td>
                            </tr>
                            <tr>
                              <td>Status</td>
                              <td>{{ confirmTransactionDetails.status }}</td>
                            </tr>
                            <tr>
                              <td>Transaction ID</td>
                              <td>{{ confirmTransactionDetails.transactionID }}</td>
                            </tr>
                          </table>
                      </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-info" @click="confirmTopup">Continue<span v-if="loading" class="spinner-border text-white"></span></button>
                    <button type="button" class="btn btn-default float-right" data-dismiss="modal">Cancel</button>
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
</template>

<script>
import { repository } from '../../presenter'
export default {
  name: 'TopupWallet',
  mounted() {
    const sweetAlert = document.createElement('script')
    sweetAlert.setAttribute('src', `${this.publicPath}assets/styles/vendor_components/sweetalert/sweetalert.min.js`)
    document.head.appendChild(sweetAlert)
  },
  props:['customerData'],
  watch: {
    confirmTransactionDetails(val) {
      if (Object.keys(val).length==0){
        this.transactionMode='start';
      }
    }
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      transactionMode: 'start',
      amount: '',
      amountError: false,
      amountErrorMessage: '',

      confirmTransactionDetails: {},

      loading: false,
      success: false,
      successMessage: '',
      error: false,
      errorMessage: ''
    }
  },
  methods: {
    async topupWallet () {
      if (this.loading) return
      this.resetData()
      const topupValidation = this.validateTopupData()
      if (!topupValidation.success) return
      this.loading = true
      const data = {
        amount: this.amount,
        userID: this.customerData.id,
      }
      const response = await repository.transaction.topupWallet(data)
      this.loading = false
      if (response.success) {
        this.resetData()
        this.confirmTransactionDetails = response.data;
        this.showConfirmationPage()
        return
      }
      this.showErrorMessage(response.data)
    },

    validateTopupData () {
      const returnValue = new Object()
      if (!this.amount || this.amount<0) {
        this.amountError = true
        this.amountErrorMessage = 'Enter a valid amount'
        returnValue.success = false
        return returnValue
      }
      returnValue.success = true
      return returnValue
    },

    showConfirmationPage() {
      this.transactionMode='confirm';
    },

    async confirmTopup () {
      if (this.loading) return
      if (!this.confirmTransactionDetails.transactionID){
        showErrorMessage("Please Refresh Page. Transaction Unavailable");
        return;
      }
      this.loading = true
      let data = {
        transactionID: this.confirmTransactionDetails.transactionID,
        userID: this.customerData.id,
      }
      const response = await repository.transaction.confirmWalletTopup(data)
      this.loading = false
      if (response.success) {
        this.confirmTransactionDetails={};
        this.showSuccessMessage('Wallet Credited');
        return
      }
      this.showErrorMessage(response.data)
    },

    showSuccessMessage (message) {
      swal(message, "", "success")
      this.$refs.topup_close_button.click();
    },
    showErrorMessage (message) {
      this.error = true
      this.errorMessage = message
    },

    resetData () {
      this.amountError = false
      this.success = false
      this.successMessage = ''
      this.error = false
      this.errorMessage = ''
    }
  }
}
</script>

<style scoped>
@import url('/assets/styles/vendor_components/sweetalert/sweetalert.css');
</style>

