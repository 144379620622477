<template>
    <section>
        <div v-if="customer.live_secret_key">
            <div class="row">
                <div class="col-md-7">
                    <div class="form-group">
                        <h5>Live Secret Key</h5>
                        <div class="controls">
                            <div class="input-group">
                                <input type="text" id="live-secret-key" class="form-control" :value="customer.live_secret_key" disabled> 
                                <span class="input-group-btn">
                                    <button class="btn btn-info" type="button" @click="copyToClipBoard('live-secret-key')">Copy</button>
                                </span> 
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <h5>Test Secret Key</h5>
                        <div class="controls">
                            <div class="input-group">
                                <input type="text" id="test-secret-key" class="form-control" :value="customer.test_secret_key" disabled> 
                                <span class="input-group-btn">
                                    <button class="btn btn-info" type="button" @click="copyToClipBoard('test-secret-key')">Copy</button>
                                </span> 
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <button class="btn btn-lg btn-primary" @click="deactivateApi">Deactivate API Access<span v-if="loading" class="spinner-border text-primary"></span></button>
                    </div>
                </div>
                <div class="col-md-1"></div>
                <div v-if="subscriptionInformation && subscriptionInformation.current_subscription_information" class="col-md-4">
                    <div  class="row">
                        <h5>Subscription Location For API</h5>
                    </div>

                    <div class="row">
                        <div>
                            <form @submit.prevent="onSubmit" class="form">
                                <div v-for="location in subscriptionInformation.current_subscription_information.user_locations" :key="location.id" class="form-group">
                                    <div class="radio">
                                        <input v-model="subscriptionLocationForApi" name="api-location" type="radio" :id="`location_${location.id}`" :value="location.id" :checked="location.id==customer.api_user_subscription_location_id" class="with-gap radio-col-red">
                                        <label :for="`location_${location.id}`">{{ location.address }} {{ location.city.name }} {{ location.state.name }}, {{ location.country.name }}</label>                    
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group">
                            <button class="btn btn-primary" @click="updateLocation">Update API Location<span v-if="loading" class="spinner-border text-white"></span></button>
                        </div>
                    </div>
                    <div class="row">
                        <p class="text-danger" v-if="error">{{errorMessage}}</p>
                    </div>
                </div>

                
                
            </div>
        </div>
        <div v-else class="row d-flex">
            <button class="btn btn-lg btn-primary" @click="activateApi">Activate API Access<span v-if="loading" class="spinner-border text-white"></span></button>
        </div>
    </section>
</template>

<script>
import { repository } from '../../presenter';
import { mapGetters } from 'vuex';
export default {
    name: 'CustomerAPIAccess',
    props: ['customerData'],
    mounted() {
        const sweetAlert = document.createElement('script')
        sweetAlert.setAttribute('src', '/assets/styles/vendor_components/sweetalert/sweetalert.min.js')
        document.head.appendChild(sweetAlert)

        // this.loadCustomerAPIAccess();
        this.loadCustomerSubscriptionInformation();
    },
    data(){
        return {
            publicPath: process.env.BASE_URL,
            customer:this.customerData,
            subscriptionInformation: null,
            subscriptionLocationForApi: this.customerData.api_user_subscription_location_id,

            loading: false,
            error: false,
            errorMessage:'',
        }
    },
    computed: {
        
    },
    methods:{
        loadCustomerAPIAccess() {
            const data = {
                userID: this.customerData.id,
            }
            // repository.customer.loadCustomerAPIAccess(data);
        },

        copyToClipBoard(identifier) {
            /* Get the text field */
            var copyText = document.getElementById(identifier);

            /* Select the text field */
            copyText.select();
            copyText.setSelectionRange(0, 99999); /* For mobile devices */

            /* Copy the text inside the text field */
            document.execCommand("copy");

            
        },

        async loadCustomerSubscriptionInformation() {
            let response = await repository.subscription.loadCustomerSubscriptionInformation(this.customerData.id);
            if (response.success){
                this.subscriptionInformation = response.data;
                console.log("subscription information is ", this.subscriptionInformation)
                return;
            }
        },

        async activateApi(){
            this.loading=true;
            const response = await repository.customer.activateApi(this.customerData.id);
            this.loading=false;
            if (response.success){
                this.customer = response.data;
                console.log("customer is ", this.customer);
                this.loadCustomerSubscriptionInformation();
            }
        },

        async deactivateApi(){
            this.loading=true;
            const response = await repository.customer.deactivateApi(this.customerData.id);
            this.loading=false;
            if (response.success){
                this.customer = response.data;
            }
        },

        async updateLocation() {
            if (this.loading) return
            this.resetData()
            // const customerDataValidation = this.validateCustomerData()
            // if (!customerDataValidation.success) return
            this.loading = true
            const data = {
                userID: this.customerData.id,
                locationID: this.subscriptionLocationForApi
            }
            console.log("this is ", this.subscriptionLocationForApi);
            const response = await repository.customer.updateCustomerAPIAccess(data)
            this.loading = false
            if (response.success) {
                this.showSuccessMessage('Customer Profile Updated');
                return
            }
            this.showErrorMessage(response.data)
        },

        validateCustomerData(){
            const returnValue = new Object()
            if (!this.email) {
                this.emailError=true;
                this.emailErrorMessage='Enter valid email';
                returnValue.success = false
                return returnValue
            }

            if (!this.phone) {
                this.phoneError=true;
                this.phoneErrorMessage='Enter valid phone number';
                returnValue.success = false
                return returnValue
            }

            returnValue.success = true
            return returnValue
        },

        showSuccessMessage (message) {
            swal(message, "", "success")
        },
        showErrorMessage (message) {
            this.error = true
            this.errorMessage = message
        },

        resetData() {
            this.error = false
            this.errorMessage = ''
        },


    }
}
</script>

<style scoped>
@import url('/assets/styles/vendor_components/sweetalert/sweetalert.css');
</style>
