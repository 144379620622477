<template>
    <section class="content">
        <div class="row">
            <div v-if="!customerSubscriptions || customerSubscriptions.length==0">
                <div>
                    <label>No Subscription Available</label>
                </div>
                <div>
                    <button class="btn btn-primary" data-toggle="modal" data-target="#subscribe-user">Subscribe User</button>
                    <SubscribeUser :customerData="customerData"/>
                </div>
            </div>
            <div v-else class="col-12">

                <div class="row mb-20">

                    <SubscribeUser :customerData="customerData"/>
                    <!-- show change plan OR both renew plan -->
                    <div v-if="customerSubscriptions[0].validity=='valid'">
                        <label>Current Subscription</label>
                        <p>{{ customerSubscriptions[0].subscription.name }}</p>
                        <button class="btn btn-primary btn-xs" data-toggle="modal" data-target="#subscribe-user">Change Plan</button>
                    </div>
                    <div v-else>
                        <label>Last Subscription</label>
                        <p>{{ customerSubscriptions[0].subscription.name }}</p>
                        <button class="btn btn-primary btn-xs" data-toggle="modal" data-target="#renew-user-subscription">Renew</button> <button class="btn btn-primary btn-xs" data-toggle="modal" data-target="#subscribe-user">Change Plan</button>
                        <RenewSubscription :customerData="customerData"/>
                    </div>
                    
                    <!-- Column -->
                    <!-- <div v-if="hasSubscription=='none'" class="col-4">
                        <label>No Subscription Available</label>
                    </div>
                    <div v-if="hasSubscription=='present'" class="col-4">
                        <label>Last Subscription</label>
                        <p>₦2500</p>
                        <button class="btn">Renew</button> <button class="btn">Change Plan</button>
                    </div>
                    <div v-else class="col-4">
                        <label>Last Subscription</label>
                        <p>₦2500</p>
                        <button class="btn">Renew</button> <button class="btn">Change Plan</button>
                    </div> -->
                    <!-- <div v-if="customerSubscriptions.current_subscription" class="col-4">
                        <label>Subscribed On: <span class="btn btn-xs btn-primary">Top Up</span></label>
                        <label>Expiring On: <span class="btn btn-xs btn-primary">Top Up</span></label>
                    </div>
                    <div v-if="customerSubscriptions.current_subscription" class="col-4">
                        <label>Subscribed On: <span class="btn btn-xs btn-primary">Top Up</span></label>
                        <label>Expired On: <span class="btn btn-xs btn-primary">Top Up</span></label>
                    </div> -->
                </div>
                
                
                
                <!-- /.col -->

                <div class="box box-body px-0">						
                    <div class="table-responsive">
                        <table id="tickets" class="table mt-0 table-hover no-wrap table-bordered" data-page-size="10">
                            <thead>
                                <tr>
                                    <th>Subscription Name</th>
                                    <th>Subscription Date</th>
                                    <th>Expiry Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="customerSubscription in customerSubscriptions" :key="customerSubscription.id">
                                    <td>{{ customerSubscription.subscription.name }}</td>
                                    <td><span>{{ customerSubscription.subscribed_on }}</span></td>
                                    <td>{{ customerSubscription.expiry_date }}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="1">

                                    </td>
                                    <td colspan="2">
                                        <div class="text-right">
                                            <ul class="pagination"> </ul>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    </section>
</template>


<script>
import { repository } from '../../presenter';
import { mapGetters } from 'vuex';
import SubscribeUser from '../../components/Customer/SubscribeUser'
import RenewSubscription from '../../components/Customer/RenewSubscription'
export default {
    components: {
        SubscribeUser,
        RenewSubscription
    },
    props: ['customerData'],
    mounted() {
        const datatable = document.createElement('script')
        datatable.setAttribute('src', `${this.publicPath}assets/styles/vendor_components/datatable/datatables.min.js`)
        document.head.appendChild(datatable)
        datatable.onload = function(){
            const dataTable2JS = document.createElement('script')
            dataTable2JS.setAttribute('src', `${this.publicPath}assets/js/pages/data-table.js`)
            document.head.appendChild(dataTable2JS)
        }

        // this.loadCustomerWalletHistory()
        this.loadCustomerSubscriptions()
    },
    data(){
        return{
            publicPath: process.env.BASE_URL,
            customer:this.customerData,
            transactionID: '',
            type: '',
        }
    },
    watch: {
        customerSubscriptions: function(val){
            console.log("customer subscription is ", val)
        }
    },
    computed: {
        ...mapGetters('subscription', {
            customerSubscriptions: 'retrieveCustomerSubscriptions',
        }),
        
    },
    methods: {
        loadCustomerSubscriptions() {
            const data = {
                userID: this.customerData.id,
                typeID: this.type.id,
            }
            repository.subscription.loadCustomerSubscriptions(data);
        },
        
    }
}
</script>

