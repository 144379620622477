<template>
    <section class="content">
        <div class="row">
            <div class="col-12">

                <div class="row">
                    <!-- Column -->
                    <div v-if="subscriptionInformation && subscriptionInformation.current_subscription_information">
                        <div class="box box-solid box-primary">
                            <div class="box-body">
                                <div>
                                    <label>Total Subscription Orders: <span>{{ subscriptionInformation.current_subscription_information.order_limit }}</span></label>
                                </div>
                                <div>
                                    <label>Subscription Orders Used: <span>{{ subscriptionInformation.current_subscription_information.order_count }}</span></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <!-- Column -->
                    <div v-if="orderStatistics==null || orderStatistics.length==0">
                        <p>No statistics available</p>
                    </div>
                    <div v-else v-for="(orderStatData, index) in orderStatistics" :key="index" class="col-md-6 col-lg-3 col-xlg-3">
                        <div :class="'box box-inverse '+orderStatData.box_type+' pull-up'">
                            <div class="box-body">
                                <div class="flexbox">
                                    <h1><i class="ti-ticket"></i></h1>
                                    <h3 class="font-light text-white text-right"> {{ orderStatData.total_count }} <br><span class="font-size-14"> {{ orderStatData.name }} </span></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Column -->
                </div>
                
                <div class="col-12">
                    <form @submit.prevent="onSubmit">
                        <div class="box bg-pale-secondary box-outline-dark">
                            <div class="box-body">
                                <div class="contact-page-aside">
                                    <ul class="list-style-none list-inline font-size-16">
                                        <li>
                                            <div class="form-group">
                                                <div class="controls">
                                                    <input v-model="orderID" type="text" name="text" class="form-control" placeholder="Order ID">
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-group">
                                                <select v-model="status" class="form-control">
                                                    <option value="">Select Status</option>
                                                    <option value="pending">Pending</option>
                                                    <option value="unpaid">Unpaid</option>
                                                    <option value="paid">Paid</option>
                                                    <option value="delivered">Delivered</option>
                                                    <option value="completed">Completed</option>
                                                </select>
                                            </div>
                                        </li>
                                        <li class="box-label float-right"><button class="btn btn-primary text-white mt-10" @click="loadCustomerOrders">Search</button></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <!-- /. box -->
                    </form>
                </div>
                <!-- /.col -->

                <div class="box box-body px-0">						
                    <div class="table-responsive">
                        <table id="tickets" class="table mt-0 table-hover no-wrap table-bordered" data-page-size="10">
                            <thead>
                                <tr>
                                    <th>Order ID</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="order in orderList" :key="order.orderID">
                                    <td>{{ order.orderID }}</td>
                                    <td><span :class="orderClass(order)">{{ order.status }}</span></td>
                                    <td>
                                        <router-link :to="{name: 'ViewOrder', params: {orderID: order.orderID}}" type="button" class="btn btn-sm btn-info-outline" data-toggle="tooltip" data-original-title="Delete"><i class="ti-eye" aria-hidden="true">view</i></router-link>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="1">
                                        <router-link :to="{name:'CreateOrder', params: {user_id:customerData.id, customerData: JSON.stringify(customerData)}}" class="btn btn-success">Create Order</router-link>
                                    </td>
                                    <td colspan="2">
                                        <div class="text-right">
                                            <ul class="pagination"> </ul>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    </section>
</template>


<script>
import { repository } from '../../presenter';
import { mapGetters } from 'vuex';
export default {
    props: ['customerData'],
    mounted() {
        const datatable = document.createElement('script')
        datatable.setAttribute('src', `${this.publicPath}assets/styles/vendor_components/datatable/datatables.min.js`)
        document.head.appendChild(datatable)
        datatable.onload = function(){
            const dataTable2JS = document.createElement('script')
            dataTable2JS.setAttribute('src', `${this.publicPath}assets/js/pages/data-table.js`)
            document.head.appendChild(dataTable2JS)
        }

        this.loadCustomerOrderStatistics()
        this.loadCustomerOrders()
        this.loadCustomerSubscriptionInformation();
    },
    data(){
        return{
            publicPath: process.env.BASE_URL,
            customer:this.customerData,
            orderID: '',
            status: '',
            subscriptionInformation: null,
        }
    },
    computed: {
        ...mapGetters('order', {
            orderStatistics: 'retrieveCustomerOrderStatistics',
            orderList: 'retrieveCustomerOrderList',
        }),
    },
    methods: {
        loadCustomerOrderStatistics() {
            repository.order.loadCustomerOrderStatistics(this.customerData.id);
        },
        loadCustomerOrders() {
            const data = {
                userID: this.customerData.id,
                orderID: this.orderID,
                status: this.status,
            }
            console.log("from customer order view just now")
            repository.order.loadSpecificCustomerOrder(data);
        },
        orderClass(order){
            switch (order.status){
                case 'initiated':
                    return 'label label-default';
                case 'pending':
                    return 'label label-danger';
                case 'unpaid':
                    return 'label label-default';
                case 'paid':
                    return 'label label-warning';
                case 'processing':
                    return 'label label-primary';
                case 'in-transit':
                case 'in_transit':
                case 'intransit':
                    return 'label label-inverse';
                case 'delivered':
                    return 'label label-success';
                case 'incomplete':
                    return 'label label-danger';
                case 'completed':
                    return 'label label-success';
                case 'cancelled':
                    return 'label label-info';
                default:
                    return 'label label-success'
            }
        },
        async loadCustomerSubscriptionInformation() {
            let response = await repository.subscription.loadCustomerSubscriptionInformation(this.customerData.id);
            if (response.success){
                this.subscriptionInformation = response.data;
                console.log("subscript information is ", this.subscriptionInformation)
                return;
            }
        }
    }
}
</script>

