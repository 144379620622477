<template>
    <div id="renew-user-subscription" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel2">Renew User Subscription</h4>
                    <button ref="renew_subscription_close_button" type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                </div>
                <div class="modal-body">

                  <div>
                    <label class="text-dark">Who's paying?</label>
                    <select v-model="payment_method" class="form-control">
                      <option value="wallet">Customer Wallet</option>
                      <option value="admin">FTD</option>
                    </select>
                  </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-info" @click="renewSubscription">Renew<span v-if="loading" class="spinner-border text-white"></span></button>
                    <button type="button" class="btn btn-default float-right" data-dismiss="modal">Cancel</button>
                </div>
                <div v-if="error" class="text-danger text-red">
                  {{ errorMessage }}
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
</template>

<script>
import { repository } from '../../presenter'
import { mapGetters } from 'vuex';
export default {
  name: 'RenewSubscription',
  mounted() {
    const sweetAlert = document.createElement('script')
    sweetAlert.setAttribute('src', `${this.publicPath}assets/styles/vendor_components/sweetalert/sweetalert.min.js`)
    document.head.appendChild(sweetAlert)
  },
  props: ['customerData'],
  
  
  data () {
    return {
      publicPath: process.env.BASE_URL,


      payment_method: 'wallet',

      loading: false,
      success: false,
      successMessage: '',
      error: false,
      errorMessage: ''
    }
  },
  methods: {
    async renewSubscription () {
      if (this.loading) return
      this.loading = true
      const data = {
        userID: this.customerData.id,
        paymentMethod: this.payment_method,
      }
      console.log('data is ', data)
      const response = await repository.subscription.renewCustomerSubscriptions(data)
      this.loading = false
      console.log('response is ', response)
      if (response.success) {
        this.resetData()
        this.showSuccessMessage('Subscription Renewed');
        return
      }
      this.showErrorMessage(response.data)
    },

    showSuccessMessage (message) {
      swal(message, "", "success")
      this.$refs.renew_subscription_close_button.click();
    },
    showErrorMessage (message) {
      this.error = true
      this.errorMessage = message
    },

    resetData () {
      this.error = false
      this.errorMessage = ''
    }
  }
}
</script>

<style scoped>
@import url('/assets/styles/vendor_components/sweetalert/sweetalert.css');
.box {
  cursor: pointer;
}
.box-selected {
  border-width: 2px;
  border-color: blue;
}
</style>

