<template>
    <div id="subscribe-user" class="modal fade in" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2" aria-hidden="true">
        <div class="modal-dialog modal-lg" style="min-width:90%">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel2">Subscribe User - Choose a subscription</h4>
                    <button ref="subscribe_user_close_button" type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                </div>
                <div class="modal-body">







                  <div class="row">
                    <!--Box deck!-->
                    <div class="col-12">
                      <div class="box-deck d-flex justify-content-around">
                        <div v-for="subscription in subscriptionList" :key="subscription.id" :id="`subscription_holder_${subscription.id}`" @click="selectSubscription(subscription)" class="box box-solid col-lg-5 col-md-3" v-bind:class="{'bg-info': (!selectedSubscription) || (selectedSubscription.id!=subscription.id), 'bg-success': (selectedSubscription) && (selectedSubscription.id==subscription.id)}">
                          <div class="box-header d-flex justify-content-between">
                            <h4 class="box-title"><strong>{{ subscription.name }}</strong></h4>

                              <div v-if="(selectedSubscription) && (selectedSubscription.id==subscription.id)" class="ribbon ribbon-vertical-r bg-success"><i class="fa fa-check-circle"></i></div>

                          </div>
                          <div class="box-body">
                            <h4 class="box-title b-0 px-0 text-center">₦ {{ formatCurrency(subscription.subscription_amount) }}</h4>
                            <div v-html="subscription.features"></div>
                          </div>
                        </div>
                      </div>	
                    </div>
                  </div>


                  















                <div class="row">
                    <div v-if="locationLoading" class="spinner-border spinner text-success" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>





                  <div v-if="selectedSubscription!=null" class="row">
                    <div class="form-group">
                      <label>Pickup Location</label>
                      <div v-for="(location, index) in locationData" :key="index" class="row">
                        <div class="col-2">
                            <select v-model="location.selectedCountryID" @change="onCountryValueChange(index)" class="form-control">
                                <option value="">Select Country</option>
                                <option v-for="country in location.countryList" :key="country.id" :value="country.id">{{country.name}}</option>
                            </select>
                        </div>
                        <div class="col-3">
                            <select v-model="location.selectedStateID" @change="onStateValueChange(index)" class="form-control">
                                <option value="">Select State</option>
                                <option v-for="state in location.stateList" :key="state.id" :value="state.id">{{state.name}}</option>
                            </select>
                        </div>
                        <div class="col-3">
                            <select v-model="location.selectedCityID" class="form-control">
                                <option value="">Select City</option>
                                <option v-for="city in location.cityList" :key="city.id" :value="city.id">{{city.name}}</option>
                            </select>
                        </div>
                        <div class="col-3">
                            <input v-model="location.address" type="text" class="form-control"  placeholder="Address"/>
                        </div>
                        <div class="col-1">
                            <button @click="deleteAddress(index)" type="button" v-if="selectedSubscription.pickup_location_type=='multiple' && index!=0" class="btn btn-sm btn-danger-outline" data-toggle="tooltip" data-original-title="Delete"><i class="ti-trash" aria-hidden="true"></i></button>
                        </div>
                      </div>
                    </div>
                    <div v-if="selectedSubscription && selectedSubscription.pickup_location_type=='multiple'" class="form-group">
                        <button type="button" class="btn btn-info" @click="addAddressToDom">Add Address</button>
                    </div>
                  </div>
                  <div v-if="selectedSubscription!=null">
                    <label class="label">Who's paying?</label>
                    <select v-model="payment_method" class="form-control">
                      <option value="wallet">Customer Wallet</option>
                      <option value="admin">FTD</option>
                    </select>
                  </div>
                  <!--
                  <form class="form-horizontal form-element">
                      <div class="col-md-12 mb-20">
                          <p class="text-danger" v-if="error">{{errorMessage}}</p>
                      </div>
                      <div class="col-md-12 mb-20">
                          <div class="form-group">
                              <input v-model="firstName" type="text" class="form-control" placeholder="First Name">
                              <p class="error" v-if="firstNameError">{{firstNameErrorMessage}}</p>
                          </div>
                          <div class="form-group">
                              <input v-model="lastName" type="text" class="form-control" placeholder="Last Name">
                              <p class="error" v-if="lastNameError">{{lastNameErrorMessage}}</p>
                          </div>
                          <div class="form-group">
                              <input v-model="email" type="text" class="form-control" placeholder="Email">
                              <p class="error" v-if="emailError">{{emailErrorMessage}}</p>
                          </div>
                          <div class="form-group">
                              <input v-model="phone" type="text" class="form-control" placeholder="Phone">
                              <p class="error" v-if="phoneError">{{phoneErrorMessage}}</p>
                          </div>
                          <div class="form-group">
                              <select v-model="roleId" class="form-control">
                                  <option value="">Select Role</option>
                                  <option v-for="role in roles" :key="role.id" :value="role.id">{{role.name}}</option>
                              </select>
                              <p class="error" v-if="roleError">{{roleErrorMessage}}</p>
                          </div>
                      </div>
                  </form>
                  -->
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-info" @click="saveSubscription">Subscribe<span v-if="loading" class="spinner-border text-white"></span></button>
                    <button type="button" class="btn btn-default float-right" data-dismiss="modal">Cancel</button>
                </div>

                <div v-if="error" class="text-danger text-red">
                  {{ errorMessage }}
                </div>
            </div>
            <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
</template>

<script>
import { repository } from '../../presenter'
import { mapGetters } from 'vuex';
export default {
  name: 'SubscribeUser',
  mounted() {
    const sweetAlert = document.createElement('script')
    sweetAlert.setAttribute('src', `${this.publicPath}assets/styles/vendor_components/sweetalert/sweetalert.min.js`)
    document.head.appendChild(sweetAlert)

    if (this.subscriptionList==null || this.subscriptionList.length==0){
      this.loadSubscriptionList();
    }
    // this.loadCountries();
  },
  props: ['customerData'],
  computed: {
    ...mapGetters('subscription', {
            subscriptionList: 'retrieveSubscriptionList',
        }),
  },
  watch: {
    // selectedCountryID: function(country_id){
    //   console.log("country id is ", country_id)
    //     if(country_id==null) return;
    //     this.resetStateData();
    //     this.resetCityData();
    //     console.log("loading states")
    //     this.loadStates(country_id);
    // },
    // selectedStateID: function(state_id){
    //   console.log("state is ", state_id)
    //     if(state_id==null) return;
    //     this.resetCityData();
    //     this.loadCities(state_id);
    // },
    // pickupLocationNumberCount: function(arr) {
    //     for (var i=0; i<arr.length; i++) {

    //     }
    // }
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,

      countryList: [],
      stateList: [],
      cityList: [],
      locationData: [],
      locationLoading: false,

      payment_method: 'wallet',
      
      selectedSubscription: null,
      selectedCountryID: 0,
      selectedStateID: 0,
      selectedCityID: 0,

      loading: false,
      success: false,
      successMessage: '',
      error: false,
      errorMessage: ''
    }
  },
  methods: {

    loadSubscriptionList(){
      repository.subscription.fetchSubscriptions();
    },

    async onCountryValueChange(locationDataIndex) {
        const data = {
          countryID : this.locationData[locationDataIndex].selectedCountryID,
        }
        this.locationLoading = true;
        const stateResponse = await repository.regions.fetchStates(data, 'subscription', null, this.selectedSubscription.id);
        this.locationLoading = false;
        console.log("state response is ", stateResponse)
        if (stateResponse.success){
            this.locationData[locationDataIndex].stateList = stateResponse.data;
            this.locationData[locationDataIndex].selectedStateID = '';
            this.locationData[locationDataIndex].selectedCityID = '';
            this.locationData[locationDataIndex].selectedCityList = [];
            return;
        }
    },

    async onStateValueChange(locationDataIndex) {
        const data = {
          stateID : this.locationData[locationDataIndex].selectedStateID,
        }
        this.locationLoading = true;
        const cityResponse = await repository.regions.fetchCities(data, 'subscription', null, this.selectedSubscription.id);
        this.locationLoading = false;
        console.log("city response is ", cityResponse)
        if (cityResponse.success){
            this.locationData[locationDataIndex].cityList = cityResponse.data;
            this.locationData[locationDataIndex].selectedCityID = '';
            return;
        }
    },
    boundName(bound){
      switch (bound){
        case 'inbound':
          return 'within';
        default:
          return 'outer';
      }
    },

    boundLocations(locations){
      let value = '';
      for (var i=0; i<locations.length; i++){
        if (i==0) value += locations[i].bound_type_name;
        else value += ` or ${locations[i].bound_type_name}`;
      }
      return value;
    },

    async selectSubscription(subscription){
      this.selectedSubscription = subscription;
      console.log("selected subscription is ", subscription)
      this.locationData = []
      
      // this.loadCountries()
      const response = await repository.regions.fetchCountries(null, 'subscription', null, this.selectedSubscription.id)
      this.countryList = response.data;
      this.locationData.push({
        "countryList": this.countryList,
        "selectedCountryID": '',
        "stateList": [],
        "selectedStateID": '',
        "cityList": [],
        "selectedCityID": '',
        "address": '',
      });

      console.log("after selecting location data is ", this.locationData)


      // this.stateList= []
      // this.cityList= []
      // this.selectedCountryID = '';
      // this.selectedStateID = '';
      // this.selectedCityID = '';
    },

    addAddressToDom() {
      this.locationData.push({
        "countryList": this.countryList,
        "selectedCountryID": '',
        "stateList": [],
        "selectedStateID": '',
        "cityList": [],
        "selectedCityID": '',
        "address": '',
      });
    },

    deleteAddress(index){
      this.locationData.splice(index, 1);
      console.log("Location data after splice is ", this.locationData);
    },

    createAddressList(finalCompilation){
      const selectedAddressList = []
      for (var location of this.locationData) {
        if (!location.selectedCountryID){
            alert("Country Value missing! Check your location selections")
            return false;
        }
        if (!location.selectedStateID){
            alert("State Value missing! Check your location selections")
            return false;
        }
        if (!location.selectedCityID){
            alert("City Value missing! Check your location selections")
            return false;
        }
        if (!location.address){
            alert("Address Value missing! Check your location selections")
            return false;
        }

        if (finalCompilation) {
          selectedAddressList.push({
            "country_id": location.selectedCountryID,
            "state_id": location.selectedStateID,
            "city_id": location.selectedCityID,
            "address": location.address,
          })
          // location.countryList = [];
          // location.stateList = [];
          // location.cityList = [];
          // location.country_id = location.selectedCountryID;
          // location.state_id = location.selectedStateID;
          // location.city_id = location.selectedCityID;


        }

      }

      return selectedAddressList;


      // this.selectedAddressList = [];
      // console.log("entered function")
      // for (var x of this.pickupLocationNumberCount){
      //   let address = this.$refs[`address_for_${x}`].value;
      //   let city_id = this.$refs[`city_for_${x}`].value;
      //   let state_id = this.$refs[`state_for_${x}`].value;
      //   let country_id = this.$refs[`country_for_${x}`].value;

      //   console.log(city_id, this.$refs[`city_for_${x}`].value, this.$refs[`city_for_${x}`].value.id)


      //   if (!address || !city_id || !state_id || !country_id){
      //       alert("Values missing! Check your location selections")
      //       return false;
      //   }

      //   this.selectedAddressList.push({
      //     'address': address,
      //     'city_id': city_id,
      //     'state_id': state_id,
      //     'country_id': country_id,
      //   });
      // }
      // return true;
    },





    async saveSubscription () {
        if (this.loading) return
        this.resetData()
        const subscribeUserDataValidation = this.validateSubsribeUserData()
        if (!subscribeUserDataValidation.success) return
        this.loading = true
        const data = {
          userID: this.customerData.id,
          subscriptionID: this.selectedSubscription.id,
          locations: this.createAddressList(true),
          paymentMethod: this.payment_method,
        }
        console.log('data is ', data)
        const response = await repository.subscription.subscribeUser(data)
        this.loading = false
        console.log('response is ', response)
        if (response.success) {
          this.resetData()
          this.showSuccessMessage('User Subscribed');
          return
        }
        this.showErrorMessage(response.data)
    },

    validateSubsribeUserData () {
      const returnValue = new Object()
      if (!this.selectedSubscription) {
        this.error = true
        this.error = 'Select A Subscription'
        returnValue.success = false
        return returnValue
      }
      if (!this.createAddressList()) {
        returnValue.success = false
        return returnValue
      }
      returnValue.success = true
      return returnValue
    },

    showSuccessMessage (message) {
      swal(message, "", "success")
      this.$refs.subscribe_user_close_button.click();
    },
    showErrorMessage (message) {
      this.error = true
      this.errorMessage = message
    },

    resetData () {
      this.error = false
      this.errorMessage = ''
    },

    formatCurrency(amount) {
        var amount = parseFloat(amount);
        return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    },
  }
}
</script>

<style scoped>
@import url('/assets/styles/vendor_components/sweetalert/sweetalert.css');
.box {
  cursor: pointer;
}
.box-selected {
  border-width: 2px;
  border-color: blue;
}
</style>

