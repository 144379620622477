<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <section class="content-header">
            <h1>Customer Profile</h1>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#"><i class="iconsmind-Library"></i></a></li>
                <li class="breadcrumb-item"><router-link :to="{path: '/customers'}">Customers</router-link></li>
                <li class="breadcrumb-item active">customer profile</li>
            </ol>
        </section>

        <!-- Main content -->
        <section class="content">

            <div v-if="loading" class="spinner-border spinner text-success" role="status">
                <span class="sr-only">Loading...</span>
            </div>

            <div class="row">
                <div class="col-lg-4">
                    <!-- Profile Image -->
                    <div class="box">
                        <div class="box-body box-profile">
                            <img class="profile-user-img rounded-circle img-fluid mx-auto d-block" src="@/assets/images/account2.png" alt="User profile picture">
                            <h3 class="profile-username text-center">{{ name }}</h3>
                            <p class="text-center">{{ accountType }}</p>				
                            <div class="row social-states">
                                <!-- <div class="col-6 text-right"><a href="#" class="link"><i class="ion ion-ios-people-outline"></i> 254</a></div> -->
                                <!-- <div class="col-6 text-left"><a href="#" class="link"><i class="ion ion-images"></i> 54</a></div> -->
                            </div>
                        </div>
                        <!-- /.box-body -->
                    </div>
                    <!-- /.box -->
                </div>
                <div class="col-lg-4">
                    <!-- Profile Image -->
                    <div class="box">
                        <div class="box-body box-profile">
                            <div class="profile-user-info">
                                <p>Email address </p>
                                <h4 class="mb-20">{{ email }}</h4>
                                <p>Phone</p>
                                <h4 class="mb-20">{{ phone }}</h4> 
                                <p>Verified</p>
                                <h4 :class="verified.class">{{ verified.value }}</h4>
                            </div>
                        </div>
                        <!-- /.box-body -->
                    </div>
                <!-- /.box -->
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-lg-12">
                    <div class="box box-default">
                        <div class="box-header with-border">
                            <!-- <h4 class="box-title">Vertical Tab</h4>
                            <h6 class="box-subtitle">Use default tab with class <code>vtabs & tabs-vertical</code></h6> -->
                        </div>
                        <!-- /.box-header -->
                        <div class="box-body">
                            <!-- Nav tabs -->
                            <div v-if="customer" class="vtabs">
                                <ul class="nav nav-tabs tabs-vertical" role="tablist">
                                    <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#personal-information" role="tab"><span class="hidden-sm-up"><i class="ion-person"></i></span> <span class="hidden-xs-down">Personal Information</span> </a> </li>
                                    <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#orders" role="tab"><span class="hidden-sm-up"><i class="ion-person"></i></span> <span class="hidden-xs-down">Orders</span></a> </li>
                                    <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#wallet" role="tab"><span class="hidden-sm-up"><i class="ion-email"></i></span> <span class="hidden-xs-down">Wallet</span></a> </li>
                                    <!-- <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#transactions" role="tab"><span class="hidden-sm-up"><i class="ion-email"></i></span> <span class="hidden-xs-down">Transactions</span></a> </li> -->
                                    <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#subscription" role="tab"><span class="hidden-sm-up"><i class="ion-email"></i></span> <span class="hidden-xs-down">Subscription</span></a> </li>
                                    <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#api" role="tab"><span class="hidden-sm-up"><i class="ion-email"></i></span> <span class="hidden-xs-down">API access</span></a> </li>
                                </ul>
                                <!-- Tab panes -->
                                <div class="tab-content" style="width: 100%;">
                                    <div class="tab-pane active" id="personal-information" role="tabpanel"> <CustomerPersonalInformation  :customerData="customer"/> </div>
                                    <div class="tab-pane pad" id="orders" role="tabpanel"> <CustomerOrders :customerData="customer"/> </div>
                                    <div class="tab-pane pad" id="wallet" role="tabpanel"> <CustomerWallet :customerData="customer"/> </div>
                                    <!-- <div class="tab-pane pad" id="transactions" role="tabpanel"> <CustomerTransactions/> </div> -->
                                    <div class="tab-pane pad" id="subscription" role="tabpanel"> <CustomerSubscription :customerData="customer"/> </div>
                                    <div class="tab-pane pad" id="api" role="tabpanel"> <CustomerAPIAccess :customerData="customer"/> </div>
                                </div>
                            </div>
                        </div>
                        <!-- /.box-body -->
                    </div>
                    <!-- /.box -->
                </div>
                <!-- /.col -->
            </div>

        </section>
    </div>
</template>

<script>
import CustomerPersonalInformation from '../../components/Customer/CustomerPersonalInformation';
import CustomerOrders from '../../components/Customer/CustomerOrders';
import CustomerWallet from '../../components/Customer/CustomerWallet';
import CustomerSubscription from '../../components/Customer/CustomerSubscription';
import CustomerAPIAccess from '../../components/Customer/CustomerAPIAccess';
import { repository } from '../../presenter';
export default {
    components: {
        CustomerPersonalInformation,
        CustomerOrders,
        CustomerWallet,
        CustomerSubscription,
        CustomerAPIAccess,
    },
    props:{
        customerData:{
            type: Object
        }
    },
    data() {
        return{
            customer: this.customerData,
            loading: false,
        }
    },
    computed: {
        name() {
            if (this.customer){
                return this.customer.account_type=='business' ? this.customer.business_name : `${this.customer.first_name} ${this.customer.last_name}`
            }
            return '';
        },
        accountType() {
            return this.customer ? this.customer.account_type : '';
        },
        email() {
            return this.customer ? this.customer.email : '';
        },
        phone() {
            return this.customer ? this.customer.phone : '';
        },
        
        verified() {
            if (this.customer && this.customer.status=='verified'){
                const elementClass = 'text-success';
                const elementValue = 'YES'
                return {class:elementClass, value:elementValue};
            }else if (this.customer && this.customer.status=='unverified'){
                const elementClass = 'text-danger';
                const elementValue = 'NO'
                return {class:elementClass, value:elementValue};
            }
            return {class:'', value:''};
        }
    },
    mounted() {
        if (this.customerData==null) this.loadCustomerData()
    },
    methods: {
        async loadCustomerData() {
            this.loading=true
            let response = await repository.customer.loadCustomerData(this.$route.params.user_id);
            this.loading=false
            if (response.success){
                this.customer = response.data;
                return;
            }
            this.showError();
        },
        showError() {

        }
    }
}
</script>

<style scoped>
.spinner {
  display: block;
  position: fixed;
  width: 3rem; 
  height: 3rem;
  z-index: 1031; /* High z-index so it is on top of the page */
  top: calc( 50% - ( 3rem / 2) ); /* where ... is the element's height */
  right: calc( 50% - ( 3rem / 2) ); /* where ... is the element's width */
}
</style>

